exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-argali-filz-tsx": () => import("./../../../src/pages/argali-filz.tsx" /* webpackChunkName: "component---src-pages-argali-filz-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kuenstler-partner-tsx": () => import("./../../../src/pages/kuenstler-partner.tsx" /* webpackChunkName: "component---src-pages-kuenstler-partner-tsx" */),
  "component---src-pages-kurse-seminare-tsx": () => import("./../../../src/pages/kurse-seminare.tsx" /* webpackChunkName: "component---src-pages-kurse-seminare-tsx" */)
}

